.buttonGradient {
    background-image: linear-gradient(29deg, #bcc866 0%, #689c90) !important;
    color: #fff !important;
}

.bdrGradient {
    border-image: linear-gradient(29deg, #bcc866 0%, #689c90) !important;
    border-image-slice: 1 !important;
    border-radius: 4px !important;
}

.chakra-modal__footer {
    display: block !important;
}
.googleIcon {
    font-size: 25px;
    margin-right: 10px;
}
.chakra-modal__content {
    background: #202020 !important;
    color: #fff !important;
}
.rec-pagination {
    margin: 0;
}
.CarouselCollection {
    position: relative;
}
.CarouselCollection .rec-arrow-left,
.CarouselCollection .rec-arrow-right {
    border-radius: 4px;
    color: #fff;
    background: #303030;
    width: 35px !important;
    height: 35px;
    padding: 0;
    line-height: 35px;
    min-width: 35px;
    font-size: 16px;
}

.CarouselCollection .rec-arrow-left {
    position: absolute;
    left: -40px;
}
.CarouselCollection .rec-arrow-left:hover,
.CarouselCollection .rec-arrow-right:hover {
    color: #fff;
    background-color: #303030 !important;
    box-shadow: 0 0 2px 0 #333;
}
.CarouselCollection .rec-arrow-right {
    position: absolute;
    right: -40px;
}
.rec-dot {
    margin: 7px 3px !important;
    background-color: #fff !important;
    height: 6px !important;
    width: 6px !important;
    box-shadow: 0 0 1px 2px rgb(0 0 0 / 50%);
}
.rec-dot_active {
    background-color: #a1ae43 !important;
    box-shadow: none !important;
    margin: 5px 3px !important;
    height: 10px !important;
    width: 10px !important;
}
.itemBg {
    background: #404040 !important;
    color: #fff;
}
.rec-slider {
    margin: 0 !important;
}
.rec-slider-container {
    margin: 0 !important;
}
.chakra-tabs .chakra-tabs__tab[aria-selected='true'] {
    color: #fff !important;
    border-image: linear-gradient(29deg, #bcc866 0%, #689c90) !important;
    border-image-slice: 1 !important;
    background: none !important;
}
.chakra-tabs .chakra-tabs__tab:focus {
    outline: none !important;
    box-shadow: none !important;
}

.footerPage svg {
    font-size: 23px;
}
.footerPage .fb {
    color: #3b5998;
}
.footerPage .tw {
    color: #55acee;
}
.footerPage .insta {
    color: #ef4056;
}
.footerPage .copyRight {
    display: flex;
    align-items: center;
}
.footerPage ul li {
    list-style: none;
    padding: 10px 0;
}
.footerPage form {
    display: flex;
}
.footerPage .chakra-container {
    margin-bottom: -160px;
    position: relative;
}

.pagination-container {
    display: flex;
    list-style-type: none;
    align-items: center;
}

.pagination-item {
    padding: 0 12px;
    height: 32px;
    text-align: center;
    margin: auto 4px;
    color: rgba(255, 255, 255, 1);
    display: flex;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: 0.01071em;
    border-radius: 16px;
    line-height: 1.43;
    font-size: 13px;
    min-width: 32px;
}

.pagination-item.dots:hover {
    background-color: transparent;
    cursor: default;
}
.pagination-item:hover {
    background-color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
}

.pagination-item.selected {
    background-color: rgba(255, 255, 255, 0.5);
}

.pagination-item.disabled {
    pointer-events: none;
}

.pagination-item.disabled:hover {
    background-color: transparent;
    cursor: default;
}
