html,
body {
	padding: 0;
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
		sans-serif;
}

a {
	color: inherit;
	text-decoration: none;
}

* {
	box-sizing: border-box;
}

.itemBg {
	background: #404040 !important;
	color: #fff;
}

.AccountLink {
	display: inline-flex;
	width: 100%;
	padding: 5px;
	align-items: center;
	line-height: 16px;
}

.AccountLink svg {
	font-size: 20px;
	margin-right: 10px;
}
.AccountLink:focus {
	box-shadow: none !important;
}

.AccountLink:hover,
.AccountLink.active {
	background-image: linear-gradient(29deg, #bcc866 0%, #689c90) !important;
	color: #fff !important;
	text-decoration: none !important;
	border-radius: 4px;
}
input[type='file'] {
	display: none;
}
.uploadFile label {
	max-width: 100%;
	height: 100%;
	display: block;
	align-items: normal;
	-webkit-box-align: inherit;
	border: 1px solid #7f7f7f;
}
.uploadFile label:hover {
	border-color: #555;
}
.uploadFile label:focus {
	z-index: 1;
	border-color: #63b3ed;
	box-shadow: 0 0 0 1px #63b3ed;
}
.uploadFile label svg {
	margin: auto;
}

.uploadFile label div {
	display: block;
}

.uploadFile label .lmtDdb > span {
	color: #fff;
	display: block;
	font-size: 14px;
}
.uploadFile svg {
	stroke: #bcc866 !important;
}
.uploadFile label .lmtDdb .file-types {
	overflow: inherit;
	white-space: nowrap;
	text-overflow: ellipsis;
	margin-top: 5px;
	font-size: 12px !important;
	display: inline-table;
}
.uploadFile label .lmtDdb > span span {
	color: #bcc866;
}
.nftVideo {
	width: 100%;
	height: 268px !important;
}
.rec-item-wrapper {
	display: block !important;
	height: 100%;
}
